import { Box, Typography } from "@mui/material";
import Header from "../components/Header";
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';


export default function Schedule() {
  const isMobile = useMediaQuery('(max-width:500px)');
  const defaultTheme = createTheme();

    return (
      <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
          <Header text='Como será o nosso dia' />
          {!isMobile && (<Typography color='gray' 
                sx={{
                textAlign: 'center',
                width: '100%',
                fontSize: 52,
                alignItems: 'center',
                fontWeight: 500,
                fontFamily:'Allura',
                paddingTop: '60px'
                }}>
                Como será o nosso dia
            </Typography>)}

            <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' marginBottom='30px'>
              <Box display='flex' flexDirection='row' justifyContent="center" alignItems='center' paddingTop= {isMobile ? '50px' : '40px'} >
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: isMobile ? '70%' : '50%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontWeight: 400
                }}>
                  O casamento português é um pouco diferente do brasileiro, começando pela duração (14 horas de festa!!!).
              </Typography>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: isMobile ? '70%' : '50%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontWeight: 400
                }}>
                  Vamos, então, explicar um pouco como será esse nosso evento estilo ópera com vários atos.
              </Typography>
              </Box>
            </Box>
            
            <Box sx={{marginTop:7}}>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontWeight: 400
                }}>
                  Primeiro ato (por volta das 14h):
              </Typography>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontStyle: 'italic',
                  fontWeight: 300
                }}>
                  Matar as saudades e... nos casar!!!
              </Typography>
            </Box>
            <Box sx={{marginTop:4}}>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontWeight: 400
                }}>
                  Segundo ato (lá pras 16h): 
              </Typography>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontStyle: 'italic',
                  fontWeight: 300
                }}>
                  Aproveitar a comida e a bebida ouvindo uma musiquinha boa
              </Typography>
            </Box>
            <Box sx={{marginTop:4}}>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontWeight: 400
                }}>
                  Terceiro ato (por volta das 20h): 
              </Typography>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontStyle: 'italic',
                  fontWeight: 300
                }}>
                  Sentar para apreciar o jantar
              </Typography>
            </Box>
            <Box sx={{marginTop:4}}>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontWeight: 400
                }}>
                  Quarto ato (já na virada pro amanhã): 
              </Typography>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontStyle: 'italic',
                  fontWeight: 300
                }}>
                  Vamos cortar o bolo e brindar!
              </Typography>
            </Box>
            <Box sx={{marginTop:4}}>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontWeight: 400
                }}>
                  Quinto ato: 
              </Typography>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontStyle: 'italic',
                  fontWeight: 300
                }}>
                  Sobremesas liberadas
              </Typography>
            </Box>
            <Box sx={{marginTop:4}}>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 24,
                  alignItems: 'center',
                  fontWeight: 400
                }}>
                  Sexto e último ato (aqui o importante é quem vai aguentar até o final): 
              </Typography>
              <Typography color='gray' 
                sx={{
                  textAlign: 'center',
                  width: '100%',
                  fontSize: isMobile ? 16 : 30,
                  alignItems: 'center',
                  fontStyle: 'italic',
                  fontWeight: 300
                }}>
                  FESTA!!!
              </Typography>
            </Box>
          </Box>
        </ThemeProvider>
    );
}