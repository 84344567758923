import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Countdown from '../../components/Countdown';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const weddingText = 'A única certeza que quero ter é viver intensamente essa incerteza'
const weddingDay = '04 de Outubro de 2024'
const weddingPlace = 'Quinta do Avesso, Portugal'
const defaultTheme = createTheme();

export const HomePage = () => {
  const isMobile = useMediaQuery('(max-width:500px)');

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header />
          <Box display='flex' justifyContent='center' sx={{
                marginTop: 3
                }}>
              </Box>
              <Typography color='gray' gutterBottom fontFamily='Allura'
            sx={{
              textAlign: 'center',
              width: '100%',
              fontSize: isMobile ? 50 : 72,
              marginTop: isMobile ? '15px' : '35px'
            }}>
              Ana Catarina e Lukas
          </Typography>
              <Countdown />    
      
       
       
        <Box>
          <Typography color='gray' gutterBottom fontFamily='Allura'
            sx={{
              textAlign: 'center',
              width: '100%',
              fontSize: isMobile ? 32 : 48,
              marginTop: isMobile ? 4 : 7,
              padding:2
            }}>
              {weddingText}
          </Typography>
          {/* <Box display='flex' justifyContent='end' marginRight= {isMobile ? '40px' : '500px'} >
          {<img style={{ width:'90px', transform: 'rotate(260deg)', filter: 'grayscale(20%)'}} src={photoFolhas.image}/>}
          </Box> */}
          <Typography color='gray' gutterBottom fontFamily='Roboto'
            sx={{
              textAlign: 'center',
              width: '100%',
              fontSize: isMobile ? 15 : 25,
              marginTop: isMobile ? 4 : 7,
              fontWeight: 300
            }}>
              {weddingDay}
          </Typography>
          <Typography color='gray' gutterBottom fontFamily='Roboto'
            sx={{
              textAlign: 'center',
              width: '100%',
              fontSize: isMobile ? 15 : 25,
              marginTop: 0,
              fontWeight: 300
            }}>
              {weddingPlace}
          </Typography>
          {/* <Box display='flex' justifyContent='start' marginLeft={isMobile ? '40px' : '500px'}>
          {<img style={{ width:'90px', transform: 'rotate(70deg)', filter: 'grayscale(20%)'}} src={photoFolhas.image}/>}
          </Box> */}
          
        </Box>
     <Footer />
    </ThemeProvider>
  );
}