import { Box, Typography } from '@mui/material'
import Header from '../components/Header'
import useMediaQuery from '@mui/material/useMediaQuery'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';


export default function Location() {
    const isMobile = useMediaQuery('(max-width:500px)');
  
    const defaultTheme = createTheme();

return (
<ThemeProvider theme={defaultTheme}>
<CssBaseline />
      <Header text='O Bulezinho Azul' />
      {!isMobile && (<Typography color='gray' 
          sx={{
          textAlign: 'center',
          width: '100%',
          fontSize: 52,
          alignItems: 'center',
          fontWeight: 500,
          fontFamily:'Allura',
          paddingTop: '60px'
          }}>
          O Bulezinho Azul
      </Typography>)}
<Box>
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' sx={{ marginTop: '30px', marginBottom: '30px' }}>
        <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 16 : 24,
              alignItems: 'center',
              fontWeight: 400,
              marginBottom:'25px'
            }}
        >
            Esse é um conto escrito pela Dona Ana quando decidimos que queríamos representar o amor com a entrada das alianças
        </Typography>

        <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 12 : 20,
              alignItems: 'center',
              fontWeight: 400
            }}
        >
            Em maio de 1966, quando paramos em frente a uma vitrine de uma rua qualquer do centro do Rio de Janeiro, nossos olhares se encantaram com um pequeno bule azul de porcelana. Ele estava acompanhado por um açucareiro e seis pequenas xícaras de café com seus respectivos pires, todos da mesma cor. Elegantemente colocado no centro de uma bela caixa, parecia ser o presente perfeito para um casal de noivos.
        </Typography>
        <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 12 : 20,
              alignItems: 'center',
              fontWeight: 400
            }}
        >
            “ – Ele é lindo e perfeito para nós! -- sussurrei.”
        </Typography>
        <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 12 : 20,
              alignItems: 'center',
              fontWeight: 400
            }}
        >
            Na loja ao lado, havíamos compramos um filtro de barro para que pudéssemos consumir a água do dia a dia no minúsculo apartamento conjugado, que ainda não estava pronto para nos acolher. Tínhamos as chaves, mas faltavam móveis, utensílios para as refeições e cortinas.
            Havíamos passado cinco meses escondidos em um hotel/pensão naqueles arredores, um retiro necessário para recompor as energias desgastadas por dois anos de lutas familiares, tudo  para viver o amor que pulsava forte em nossos corações. Às vezes, nos pegávamos rindo das noites no hotel, quando dividíamos um pequeno colchão e sonhávamos com o dia em que teríamos um lugar todo nosso. Cada pequena vitória, conseguida mês a mês, como as chaves do apartamento, era celebrada com um beijo carinhoso cheio de promessas.
        </Typography>
        <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 12 : 20,
              alignItems: 'center',
              fontWeight: 400
            }}
        >
            Mas o bule azul parecia que nos queria tanto quanto nós a ele... Atraídos para dentro da loja, saímos felizes com aquela segunda aquisição do dia: o bule azul e seus acompanhantes!
        </Typography>
        <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 12 : 20,
              alignItems: 'center',
              fontWeight: 400
            }}
        >
            Caminhamos de volta para casa, a pé. Atravessamos o Campo de Santana sorridentes e ansiosos, porque agora poderíamos receber nossas visitas com um café quentinho servido no elegante bule azul.
            Ele passou a representar a luta daquele solitário caminhar a dois que fomos obrigados a fazer um dia. Ficou como marca de uma meta: receber visitas, sermos aceitos por todos aqueles que não acreditavam na história de amor que ali se iniciava. Muitos torceram contra, muitos apareceram só para verificar se era verdade, mas poucos provaram do cafezinho do bule azul.
        </Typography>
        <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 12 : 20,
              alignItems: 'center',
              fontWeight: 400,
              marginBottom:'25px'
            }}
        >
            Hoje, passados 58 anos e 5 meses, ele continua a ser o ícone da nossa vitória. Está exposto na nossa sala como uma relíquia acompanhada apenas do açucareiro, seu fiel companheiro. Resistiram aos reboliços e tropeços da vida difícil que fomos obrigados a enfrentar.
            Ao olhar para ele na estante da sala, sinto um turbilhão de emoções. Ele é o testemunho silencioso das dificuldades superadas. A cada vez que alguém nos visita e elogia o charme do bulezinho de porcelana, sinto uma pontada de orgulho e gratidão por termos persistido.
            Sempre acreditamos que o amor venceria, e venceu!
        </Typography>
        <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 12 : 20,
              alignItems: 'center',
              fontWeight: 400
            }}
          >
            Ana Arnaud, Penafiel, Maio de 2024
        </Typography>
    </Box>
</Box>
</ThemeProvider>
)
}