import { Box, Typography, TextField, Button, Switch, FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import Header from '../components/Header'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useState } from 'react'
import { get, put } from 'aws-amplify/api'
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';



export default function GuestList() {
  const isMobile = useMediaQuery('(max-width:500px)')
  const defaultTheme = createTheme();
  const [guests, setGuests] = useState()
  const [confirmedGuests, setConfirmedGuests] = useState()
  const [pin, setPin] = useState()
  const [step, setStep] = useState('inputPin')

  const getGuests = async (pin) => {
    try {
      const response = get({
        apiName: 'guests',
        path: `/guests/${pin}`
      })
      const log = await response.response
      const result = await log.body.text()
      setGuests(JSON.parse(result))
      setStep('confirmInvite')
    } catch (e) {
      
    }
  }

  const updateGuestsStatus = async (guests) => {
    try {
      const response = put({
        apiName: 'guests',
        path: '/guests/status',
        options: {
          body: { guests }
        }
      })
      const log = await response.response
      await log.body.json()
    } catch (e) {
      //fazer throw
    }finally{
      await getConfirmedGuests(pin)
    }
  }
    
    const getConfirmedGuests = async (pin) => {
      if (confirmedGuests === undefined) {
      try {
        const response = get({
          apiName: 'guests',
          path: `/guests/${pin}/confirmed`
        })
        const log = await response.response
        const result = await log.body.text()
        setConfirmedGuests(JSON.parse(result))
        console.log(JSON.parse(result))
        if (JSON.parse(result).length) {
          setStep('chooseDishes')
        } else {
          setStep('lastPageWithDeniedInvite')
        }
        console.log('guests confirmados', confirmedGuests)
      } catch (e) {
        //fazer throw
      }
    }
  }

  const updateGuestsDishes = async(confirmedGuests) => {
    try {
      const response = put({
        apiName: 'guests',
        path: '/guests/dishes',
        options: {
          body: { confirmedGuests }
        }
      })
      const log = await response.response
      await log.body.json()
      console.log('guests confirmados', confirmedGuests)
    } catch (e) {
      //fazer throw
    }finally{
      setStep('lastPageWithConfirmedInvite')
    }
  }
  
  const stepsConfig = {
    inputPin: () => {
      return (
      <Box>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' sx={{ marginTop: '30px', marginBottom: '30px' }}>
          <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 16 : 24,
              alignItems: 'center',
              fontWeight: 400
            }}
          >
            Vocês são as pessoas mais próximas e mais importantes pra gente.
          </Typography>
          <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 16 : 24,
              alignItems: 'center',
              fontWeight: 400,
              marginTop: '10px'
            }}
          >
            Um muito obrigada a todos que fizeram o esforço de vir do Brasil pra poder estar conosco nesse momento.
          </Typography>
          <Typography
            color="gray"
            sx={{
              textAlign: 'center',
              width: isMobile ? '70%' : '50%',
              fontSize: isMobile ? 16 : 24,
              alignItems: 'center',
              fontWeight: 400,
              marginTop: '10px'
            }}
          >
            Aos que já estavam perto, vale lembrar que vocês economizaram um dinheirinho bom, então lembrem-se da lista
            de presentes!
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' >
          <Typography sx={{
            textAlign: 'center',
            width: isMobile ? '70%' : '50%',
            fontSize: isMobile ? 20 : 28,
            alignItems: 'center',
            fontWeight: 400,
            marginTop: '10px',
            color:'gray'
          }}>
            Vamos então garantir seu lugar nessa festa?
          </Typography>
          <Typography sx={{
                textAlign: 'center',
                width: isMobile ? '70%' : '50%',
                fontSize: isMobile ? 20 : 28,
                alignItems: 'center',
                fontWeight: 400,
                marginTop: '10px',
                color:'gray'
              }}>
            Aqui vocês vão confirmar a presença e escolher o que vão querer para o jantar. 
          </Typography>
          <Typography sx={{
                textAlign: 'center',
                width: isMobile ? '70%' : '50%',
                fontSize: isMobile ? 20 : 28,
                alignItems: 'center',
                fontWeight: 400,
                marginTop: '10px',
                marginBottom: '50px',
                color:'gray'
              }}>
            Maravilhoso, né? Sabemos.
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems='center'
          sx={{
            
          }}
        >
          <TextField 
            id="outlined-basic" 
            label="Qual é o seu PIN?" 
            variant="outlined" 
            sx={{marginRight:'20px', color:'gray'}}
            onChange={(event) => setPin(event.target.value)}
          />
          <Button sx={{padding:'15px', backgroundColor: '#adccea', color:'#47617b'}} onClick={async () => await getGuests(pin)}>Verificar</Button>
        </Box>
      </Box>
      )
    },
    confirmInvite: () => {
      return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center'>
        <Typography sx={{
          textAlign: 'center',
          width: isMobile ? '70%' : '50%',
          fontSize: isMobile ? 20 : 28,
          alignItems: 'center',
          fontWeight: 400,
          marginBottom: '40px',
          marginTop: '40px',
          color:'gray'
          }}>
      Vamos confirmar as presenças? Seleciona aqui quem vai aproveitar com a gente!
        </Typography>
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
      {guests.map((guest) => (
        <FormControlLabel
        label={guest.nome}
          control={
            <Checkbox 
            size= {isMobile ? "small" : "medium"}
              checked={guest.estaConfirmado} 
              sx={{color:'gray'}}
              onChange={
                (event) => setGuests(guests.map(
                  (guest) => guest.nome === event.target.name ? { ...guest, estaConfirmado: event.target.checked } : guest))} 
              name={guest.nome} 
            />
          }
          
        />
      ))}
      </FormGroup>
    </FormControl>
    <Button sx={{padding:'15px', backgroundColor: '#adccea', color:'#47617b', marginTop: '50px'}} onClick={() => updateGuestsStatus(guests)}>Próximo passo</Button>
    </Box>
      )
    },
    chooseDishes: () => {
      return (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center'> 
        <Typography sx={{
            textAlign: 'center',
            width: isMobile ? '70%' : '50%',
            fontSize: isMobile ? 20 : 28,
            alignItems: 'center',
            fontWeight: 400,
            marginBottom: '30px',
            color:'gray'
            }}>
            Ieba, vamos para o próximo passo!
          </Typography>
          <Typography sx={{
            textAlign: 'center',
            width: isMobile ? '70%' : '50%',
            fontSize: isMobile ? 20 : 28,
            alignItems: 'center',
            fontWeight: 400,
            marginBottom: '30px',
            color:'gray'
            }}>
            Chegou a hora de escolher o jantar! Tchanãnãnãããã...
          </Typography>
              {confirmedGuests.map((confirmedGuest) => 
              <Box sx={{marginTop: '20px'}}>
                <Typography sx={{ fontWeight: '400'}}>
                  {confirmedGuest.nome}
                </Typography>
                <FormControl sx={{ m: 1, width: '350px', display:'flex', flexDirection: 'column' }} size="small">
                  <InputLabel id="demo-select-small-label-entrada">Entrada</InputLabel>
                  <Select
                    labelId="demo-select-small-label-entrada"
                    id="demo-select-small"
                    value={confirmedGuest.entrada}
                    label="Entrada"
                    onChange={
                    (event) => setConfirmedGuests(confirmedGuests.map(
                      (guest) => guest.nome === confirmedGuest.nome ? ({ ...guest, entrada: event.target.value }) : guest))} 
                  >
                    <MenuItem value="" color="gray">
                      <em>Vou fazer jejum</em>
                    </MenuItem>
                    <MenuItem value={'Croquetes'}>Croquetes de Alheira e Amêndoa</MenuItem>
                    <MenuItem value={'Variações'}>Variações de Tomate, Pesto e Requeijão</MenuItem>
                  </Select>
                </FormControl>
                <FormControl id={confirmedGuest.nome} sx={{ width: '350px', m: 1, minWidth: 120, display:'flex', flexDirection: 'column' }} size="small">
                  <InputLabel id="demo-select-small-label-prato1">Prato 1</InputLabel>
                  <Select
                    labelId="demo-select-small-label-prato1"
                    id={confirmedGuest.nome}
                    value={confirmedGuest.prato1}
                    label="Prato 1"
                    onChange={
                      (event) => setConfirmedGuests(confirmedGuests.map(
                        (guest) => guest.nome === confirmedGuest.nome ? ({ ...guest, prato1: event.target.value }) : guest))} 
                  >
                    <MenuItem value="">
                      <em>Jejum intermitente</em>
                    </MenuItem>
                    <MenuItem value={'Salmão'}>Salmão Grelhado com Purê de Ervilha</MenuItem>
                    <MenuItem value={'Risotto'}>Risotto de Ervilhas e Arpargos com Trufa</MenuItem>
                    <MenuItem value={'Infantil'}>Opção Infantil: Hamburguer e Arroz Xau Xau</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ width: '350px', m: 1, minWidth: 120, display:'flex', flexDirection: 'column' }} size="small">
                  <InputLabel id="demo-select-small-label-prato2">Prato 2</InputLabel>
                  <Select
                    labelId="demo-select-small-label-prato2"
                    id="demo-select-small"
                    value={confirmedGuest.prato2}
                    label="Prato 2"
                    onChange={
                      (event) => setConfirmedGuests(confirmedGuests.map(
                        (guest) => guest.nome === confirmedGuest.nome ? ({ ...guest, prato2: event.target.value }) : guest))} 
                  >
                    <MenuItem value="">
                      <em>Já comi demais</em>
                    </MenuItem>
                    <MenuItem value={'Black Angus'}>Black Angus com Purê de Mandioca</MenuItem>
                    <MenuItem value={'Gnochis'}>Gnochis de Abóbora, Pesto e Espinafres</MenuItem>
                    <MenuItem value={'Infantil'}>Opção Infantil: Hamburguer e Arroz Xau Xau</MenuItem>
                  </Select>
                </FormControl>
          </Box>
        )}
                <Button 
                  sx={{
                    padding:'15px', 
                    backgroundColor: '#adccea', 
                    color:'#47617b', 
                    marginTop: '30px'
                  }} 
                  onClick={() => updateGuestsDishes(confirmedGuests)}
                >
                  Confirmar minhas escolhas
                </Button>

              </Box>
      )
    },
    lastPageWithConfirmedInvite: () => {
      return (
<Box sx={{margin: '25px'}}>
                      <Typography sx={{color:'gray', marginBottom:'15px', fontSize: !isMobile && 24}}>
                        Já faltou mais para esse dia!!!
                      </Typography>
                      <Typography sx={{color:'gray', marginBottom:'15px', fontSize: !isMobile && 24}}>
                        Hora de quem ainda não comprou as passagens, comprar! Quem já comprou, pode ver a nossa lista de presentes, ela está ótima!
                      </Typography>
                      <Typography sx={{color:'gray', marginBottom:'15px', fontSize: !isMobile && 24}}>
                        Estamos super ansiosos e animados e esperamos que vocês estejam também. 
                      </Typography>
                      <Typography sx={{color:'gray', marginBottom:'15px', fontSize: !isMobile && 24}}>
                        Muito obrigado por vocês estarem se preparando para passar esse dia com a gente!
                      </Typography>
                      <Typography sx={{color:'gray', marginBottom:'15px', fontSize: !isMobile && 24}}>
                        NOS VEMOS DIA 04.10.24
                      </Typography>
                    </Box>
      )
    },
    lastPageWithDeniedInvite: () => {
      return (
        <Box>
        <Typography sx={{color:'gray', marginBottom:'15px', fontSize: !isMobile && 24}}>
              Carambolas, vamos sentir sua falta...
            </Typography>
      </Box>
      )
    }
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Header text="Nossos convidados" />

      {!isMobile && (
        <Typography
          color="gray"
          sx={{
            textAlign: 'center',
            width: '100%',
            fontSize: 52,
            alignItems: 'center',
            fontWeight: 500,
            fontFamily: 'Allura',
            paddingTop: '60px'
          }}
        >
          Nossos convidados
        </Typography>
      )}
    
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems='center' 
        sx={{marginTop:'30px', marginBottom:'50px'}}
      > 
        {stepsConfig[step]()}
      </Box>

    </ThemeProvider>
  )
}
