import Box from '@mui/material/Box';
import { CardMedia } from "@mui/material";
import azulejo from './../images/azulejosMetade2.png'

export default function Footer() {
  
return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', marginTop: 6}}>
      <CardMedia image={azulejo} sx={{height: '150px'}}></CardMedia>
    </Box>
  );
}