import { Routes, Route } from 'react-router-dom'
import HomePage from './HomePage'
import Location from './Location'
import DressCode from './DressCode'
import GiftList from './GiftList'
import Schedule from './Schedule'
import Dishes from './Dishes'
import GuestList from './GuestList'
import RoutesUrl from '../utils/RoutesUrl'
import Text from './Text'

export const Pages = () => {
  return (
    <Routes>
      <Route path={RoutesUrl.Home} element={<HomePage />} />
      <Route path={RoutesUrl.Location} element={<Location />} />
      <Route path={RoutesUrl.DressCode} element={<DressCode />} />
      <Route path={RoutesUrl.GiftList} element={<GiftList />} />
      <Route path={RoutesUrl.GuestList} element={<GuestList />} />
      <Route path={RoutesUrl.Dishes} element={<Dishes />} />
      <Route path={RoutesUrl.Schedule} element={<Schedule />} />
      <Route path={RoutesUrl.Text} element={<Text />} />
    </Routes>
  )
}



