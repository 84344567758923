/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cloud_logic_custom": [
        {
            "name": "guest",
            "endpoint": "https://ps6rux6500.execute-api.eu-north-1.amazonaws.com/dev",
            "region": "eu-north-1"
        },
        {
            "name": "guests",
            "endpoint": "https://o0ilwhw7a5.execute-api.eu-north-1.amazonaws.com/dev",
            "region": "eu-north-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "eu-north-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "convidados-jantar",
            "region": "eu-north-1"
        }
    ]
};


export default awsmobile;
