import Menu from './Menu';
import { Typography, CardMedia } from "@mui/material";
import { Box } from "@mui/system";
import useMediaQuery from '@mui/material/useMediaQuery'
import azulejo from './../images/azulejosQuarto2.png'

export default function Header({ text='' }) {
    const isMobile = useMediaQuery('(max-width:500px)');
    
    return (
        
        <Box >
            <CardMedia image={azulejo} sx={{height: '200px', paddingBottom: 30}}></CardMedia>
             <Box display='flex' justifyContent='center' alignItems='center' sx={{ position: 'fixed', width:'100%', height:60, padding:'5px', zIndex:1, top: 0, backgroundColor: 'white', opacity: '80%' }}>
                <Menu color='darkslategray' />
                <Typography color='darkslategray' 
                    sx={{
                    textAlign: 'center',
                    width: '100%',
                    fontSize: 34,
                    alignItems: 'center',
                    fontWeight: 500,
                    fontFamily:'Allura',
                    display: !isMobile && 'none',
                    backgroundColor: 'transparent'
                    }}>
                    {text}
                </Typography>
            </Box>
        </Box>
    )
}