import { Box, Typography } from "@mui/material";
import Header from "../components/Header";
import useMediaQuery from '@mui/material/useMediaQuery'
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Dishes() {
  const isMobile = useMediaQuery('(max-width:500px)');
  const defaultTheme = createTheme();
    const menuBoxTitles = [
      { title: 'Menu Geral',  mapping: 'regularMenu'}, 
      { title: 'Menu Vegetariano', mapping: 'veggieMenu' }, 
      { title: 'Menu Infantil', mapping:  'kidsMenu'},
      { title: 'Sobremesas', mapping:  'desserts'}
    ]
    const menus = {
      regularMenu: [
        {
          tipo: 'Entrada',
          prato: 'Croquetes de Alheira e Amêndoa, Grelos, Mostarda e Mel' 
        }, 
        {
          tipo: 'Prato de peixe',
          prato: 'Salmão com Alcaparras, Limão, Purê de Ervilha e Aspargos grelhados' 
        },
        {
          tipo: 'Prato de carne',
          prato: 'Black Angus, Purê de Mandioca, Legumes grelhados e Jus de carne' 
        },
      ],
      veggieMenu: [
        {
          tipo: 'Entrada',
          prato: 'Variações de Tomate, Pesto e Requeijão' 
        }, 
        {
          tipo: 'Prato veggie',
          prato: 'Risotto de Ervilhas e Arpargos com Trufa' 
        },
        {
          tipo: 'Prato veggie',
          prato: 'Gnochis de Abóbora, Pesto e Espinafres' 
        }
      ],
      kidsMenu: [
        {
          tipo: 'Entrada',
          prato: 'Creme de legumes' 
        }, 
        {
          tipo: 'Prato Principal',
          prato: 'Hambúrguer com Ovo e Arroz Xau Xau' 
        }
      ],
      desserts: [
        {
          tipo: '',
          prato: 'Buffet de sobremesas e bolo do casamento'
        }
      ]
    }
    return (
      <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
            <Header text='O que iremos comer' />
            {!isMobile && (<Typography color='gray' 
                sx={{
                textAlign: 'center',
                width: '100%',
                fontSize: 52,
                alignItems: 'center',
                fontWeight: 500,
                fontFamily:'Allura',
                paddingTop: '60px'
                }}>
                O que iremos comer
            </Typography>)}

            <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' paddingTop= {isMobile ? '50px' : '40px'} sx={{width:'100%'}}>
                
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <Typography color='gray' display='flex' justifyContent='flex'
                    sx={{
                      textAlign: 'center',
                      width: isMobile ? '70%' : '50%',
                      fontSize: isMobile ? 16 : 24,
                      alignItems: 'center',
                      fontWeight: 400
                    }}>
                        Teremos um primeiro momento de aperitivos e depois o jantar. Jantar chique, com todos sentados nos seus lugares definidos. Tipo menu degustação. Caro igual. 
                  </Typography>
                </Box>
              
            </Box>

            <Box color='gray' display='flex' flexDirection='column' gap='15px' marginTop= '50px'>
              <Typography color='gray' fontSize='38px' fontStyle='italic'>
                Sunset
              </Typography>
              <Typography color='gray' fontWeight='300'>
                Buffet de aperitivos
              </Typography>
              <Typography color='gray' fontWeight='300'>
                Bebidas
              </Typography>
            </Box>

            <Typography color='gray' fontSize='38px' marginTop='30px' fontStyle='italic'>
              Jantar
            </Typography>
            {menuBoxTitles.map((menuBox) =>
              <Box display='flex' flexDirection='column' gap='15px'marginTop='70px' key='title' >
                <Typography color='gray' fontSize='25px' fontWeight='400'>
                  {menuBox.title} 
                </Typography> 
                <Box display='flex' justifyContent='center' alignItems='center' gap= {isMobile ? '30px' : '60px'} flexDirection={isMobile ? 'column' : 'row'}>
                {menus[menuBox.mapping].map((dish) => 
                  <Box display='flex' flexDirection='column' maxWidth='200px'>
                    <Typography color='gray' fontSize='20px' fontWeight='300'>
                      {dish.tipo}
                    </Typography>
                    <Typography color='gray' fontWeight='300' marginTop='10px' marginBottom= {isMobile ? '20px' : '50px'}>
                      {dish.prato}
                    </Typography>
                  </Box>
                )}
                </Box>
              </Box>
            )}
            
            


        </ThemeProvider>
    );
}