const RoutesUrl = {
    Home: "/",
    Dishes: "/ementa",
    DressCode: "/dress-code",
    GiftList: "/lista-de-presentes",
    GuestList: "/convidados",
    Location: "/onde-sera",
    Schedule: "/cronograma",
    Text: "/bulezinho-azul"
  }

export default RoutesUrl
  